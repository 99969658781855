import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { sessionDataContext } from "../App";
import ThankyouPage from "../components/Thankyou";
import { getQuestions } from "../service/games";
import { Base_url } from "../utils/constants";
import Accuracy from "./Components/Accuracy";
import ActualPriceCard from "./Components/ActualPriceCard";
import GuessInput from "./Components/GuessInput";
import ImageCard from "./Components/ImageCard";
import NextBtn from "./Components/NextBtn";
import ScoreCard from "./Components/ScoreCard";
import Stepper from "./Components/Stepper";
import TimeLeftCard from "./Components/TimeLeftCard";
import axiosInstance from "../utils/axiosInstance";

const GuessGame = ({ updateActiveRound }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);
  const [guessedPrice, setGuessedPrice] = useState(null);
  const [isGuessSubmitted, setIsGuessSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isEndGame, setIsEndGame] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id"); // Extract 'customer_id'
  const [sessionData, setSessionData] = useContext(sessionDataContext);
  const [score, setScore] = useState(0);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    GetQuestion();
  }, []);

  const GetQuestion = async () => {
    try {
      const params = {
        module_type: "price_guessing",
      };

      let res = await getQuestions(params);

      setQuestions(res?.data?.data);
      setTimeLeft(res?.data?.data[0]?.time_limit);
    } catch (e) {}
  };

  useEffect(() => {
    if (timeLeft > 0 && !isGuessSubmitted) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0 && !isGuessSubmitted) {
      handleNext();
    }
  }, [timeLeft, isGuessSubmitted]);

  useEffect(() => {
    if (timeLeft === 0 && !isGuessSubmitted) {
      submitAnswerIfnotSelected();
    }
  }, [timeLeft]);

  const submitAnswerIfnotSelected = async () => {
    const data = {
      question_id: questions[currentQuestion]?.id,
      answer: inputValue,
      time_spent: timeLeft,
      score: calculateAccuracy(questions[currentQuestion]?.price, inputValue),
    };

    try {
      const id = customerId ? btoa(customerId) : btoa("753982145");

      const res = await axiosInstance.post(
        `/question-answer/submit-answer`,
        data,
        {
          headers: {
            user_token: id,
          },
        }
      );
      let number = calculateAccuracy(
        questions[currentQuestion]?.price,
        inputValue
      );
      setScore((pre) => Math.round(pre + Number(number)));
    } catch (e) {
      const customer = customerId ? customerId : "753982145";
      window.location.href = `/leaderboard?customer_id=${customer}`;
      console.log(e.message);
    }
  };

  const handleGuessSubmit = async () => {
    setGuessedPrice(inputValue);
    setIsGuessSubmitted(true);

    const data = {
      question_id: questions[currentQuestion]?.id,
      answer: inputValue,
      time_spent: timeLeft,
      score: calculateAccuracy(questions[currentQuestion]?.price, inputValue),
    };

    try {
      const id = customerId ? btoa(customerId) : btoa("753982145");
      const res = await axiosInstance.post(
        `/question-answer/submit-answer`,
        data,
        {
          headers: {
            user_token: id,
          },
        }
      );

      let number = calculateAccuracy(
        questions[currentQuestion]?.price,
        inputValue
      );
      setScore((pre) => Math.round(pre + Number(number)));
    } catch (e) {
      const customer = customerId ? customerId : "753982145";
      window.location.href = `/leaderboard?customer_id=${customer}`;
    }
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      // setTimeLeft(10);
      setGuessedPrice(null);
      setIsGuessSubmitted(false);
      setActiveStep((prev) => prev + 1);
      updateActiveRound(1, questions[currentQuestion + 1]?.id);
      setTimeLeft(questions[currentQuestion + 1]?.time_limit);
    } else {
      updateActiveRound(2, null);
      // console.log("End Game First Round");
    }
  };

  return (
    <React.Fragment>
      {!isEndGame ? (
        <div className="d-flex justify-content-center container mt-5 flex-column align-items-center">
          <div className="w-75 fs-2 fw-semibold heading">
            {questions[currentQuestion]?.description}
          </div>

          {/* Stepper and Leader board button */}
          <div className="w-100 d-flex justify-content-between">
            <div className="mx-auto">
              <Stepper activeStep={activeStep} totalSteps={questions.length} />
            </div>
          </div>

          {/* Game */}
          <Row className="w-100">
            <Col md={8}>
              {!isGuessSubmitted ? (
                <ImageCard
                  imageUrl={questions[currentQuestion]?.image_url}
                  question={questions[currentQuestion]?.question}
                  options={questions[currentQuestion]?.question_options}
                />
              ) : (
                <ActualPriceCard
                  actualPrice={questions[currentQuestion]?.price}
                  guessedPrice={guessedPrice}
                  question={questions[currentQuestion]?.question}
                />
              )}
            </Col>
            <Col md={4}>
              <TimeLeftCard timeLeft={timeLeft} />
              <Accuracy
                actualPrice={questions[currentQuestion]?.price}
                guessedPrice={guessedPrice}
              />
              <ScoreCard score={score} />
            </Col>
          </Row>
          <Row className="w-100 align-items-center">
            <Col md={8}>
              <GuessInput
                guessed={isGuessSubmitted}
                onGuessSubmit={handleGuessSubmit}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </Col>
            <Col md={4}>
              {!isEndGame && (
                <NextBtn
                  isGuessSubmitted={isGuessSubmitted}
                  onNext={handleNext}
                  isEndGame={isEndGame}
                />
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <div className="thank-you d-flex justify-content-center align-items-center flex-column">
            <ThankyouPage
              text={
                "Thank you for submitting your answer. Get ready for the next round if you qualify!"
              }
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GuessGame;

const calculateAccuracy = (actualPrice, guessedPrice) => {
  const accuracy =
    100 * (1 - Math.abs(actualPrice - guessedPrice) / actualPrice);
  return Math.max(0, accuracy.toFixed(2)); // Ensuring it doesn't go below 0%
};
