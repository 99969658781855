import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sessionDataContext } from "../App";
import ModalData from "../components/ModalData";
import CustomSpinner from "../components/Spinner";
import ThankyouPage from "../components/Thankyou";
import ThemeModal from "../components/ThemeModal";
import axiosInstance from "../utils/axiosInstance";
import UserLeaderBoard from "./Components/UserLeaderBoard";
import SpinWheel from "./SpinWheel";

const Dashboard = () => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState();
    const [modalData, setModalData] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [mustSpin, setMustSpin] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [roundLoading, setRoundLoading] = useState(true);
    
    const [userActiveRound, setUserActiveRound] = useState({});
    const [actualSPinData, setActualSPinData] = useState([])
    const [spinOption, spinSpinOption] = useState([]);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [leaderBoardData, setLeaderBoardData] = useState()
    const [activeRound, setActiveRound] = useState(1);
    const [prizeData, setPrizeData] = useState('')
    const [sessionStart, setSessionStart] = useState(false);

    const handleClose = () => setShowModal(false); // Close modal
    const handleShow = () => setShowModal(true); // Open modal
    const slices = spinOption.length;
    const pointerOffset = 3;
    const adjustedPrizeNumber = (prizeNumber - pointerOffset + slices) % slices;
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get("customer_id"); // Extract 'customer_id'
    const [sessionData, setSessionData] = useContext(sessionDataContext)

    useEffect(() => {
        fetchSession();
    }, []);

    const fetchSession = async () => {
        try {

            let id = localStorage.getItem("i");
            // const id = customerId ? btoa(customerId) : btoa("753982145");

            const res = await axiosInstance.get(`/session/check-wheel-session`, {
                headers: {
                    user_token: id,
                },
            });

            if (res?.status === 200) {
                setSessionStart(res?.data?.data);
                getActiveRound(res?.data?.data);
                fetchSpinChoices()
            }
        } catch (error) {
            setLoading(false);
            setRoundLoading(false)
        } finally {
            setLoading(false);
        }
    };

    const getActiveRound = async (session) => {
        try {
            // const id = customerId ? btoa(customerId) : btoa("753982145");
            let id = localStorage.getItem("i");
            const session_id = session?.id || sessionStart?.id
            const res = await axiosInstance.get(`/round?session_id=${session_id}`, {
                headers: {
                    user_token: id,
                },
            });

            if (res?.data?.code === 200) {
                if (res?.data?.data?.round == 3) {
                    navigate("/leaderboard");
                    return;
                }
                setRoundLoading(false)
                setUserActiveRound(res?.data?.data);
            }
        } catch (e) {
            console.log(e.message);
            setRoundLoading(false)
        }
    };

    const processSpinChoices = (data) => {
        const requiredRecords = 23;

        if (data.length < requiredRecords) {
            const additionalRecords = Array.from(
                { length: requiredRecords - data.length },
                () => ({ option: '0', discount_type: 'try_again' })
            );
            return [...data, ...additionalRecords];
        }

        return data?.slice(0, requiredRecords);
    };

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const fetchSpinChoices = async () => {
        try {
            const id = customerId ? btoa(customerId) : btoa("753982145");

            const res = await axiosInstance.get(`/spin-option/get`, {
                headers: {
                    user_token: id,
                },
            });

            if (res?.status === 200) {
                setActualSPinData(res?.data?.data)
                const processedData = processSpinChoices(res?.data?.data);
                const formattedData = processedData.map((item) => {
                    if (item.discount_type === "percentage") {
                        return { ...item, option: `${item.option}%` };
                    } else if (item.discount_type === "fixed_amount") {
                        return { ...item, option: `$${item.option}` };
                    } else if (item.discount_type === "try_again") {
                        return { option: "Try Again", style: { backgroundColor: "#C63378" } };
                    }
                    return item;
                });
                const shuffledData = shuffleArray(formattedData);
                spinSpinOption(shuffledData);
            }
        } catch (error) {
        } finally {
            // setLoading(false);
        }
    };


    const updateActiveRound = async (round, session_id, status) => {
        try {
            setActiveRound(round);

            if (status) {
                navigate("/leaderboard");
            }

            // const id = customerId ? btoa(customerId) : btoa("753982145");
            let id = localStorage.getItem("i");

            const body = {
                session_id: sessionStart?.id,
                round: 3,
                ...(status && { status: status }),
            };

            await axiosInstance.post(`/round`, body, {
                headers: {
                    user_token: id,
                },
            });
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleSpinClick = async () => {
        try {
            setMustSpin(true);
            const newPrizeNumber = Math.floor(Math.random() * slices); // Random prize
            const adjustedPrizeNumber = (newPrizeNumber - pointerOffset + slices) % slices; // Adjusted for pointer offset
            setPrizeNumber(newPrizeNumber);
            console.log(adjustedPrizeNumber);

            const prizeData = spinOption[adjustedPrizeNumber]; // Get selected prize data
            setPrizeData(prizeData);
            const ActualData = actualSPinData.find(
                (item) => item?.id === prizeData?.id
            );


            updateActiveRound(3, sessionStart?.id);

            if (prizeData?.option === "Try Again") {
                setModalData("Better luck next time! Spin again for a chance to win exciting rewards");
                setTimeout(() => {
                    setIsOpen(true);
                }, 3800)
                navigate("/leaderboard");
                return;
            }

            if (prizeData?.option != "Try Again") {
                const payload = {
                    discount_value: ActualData?.option,
                    discount_type: ActualData?.discount_type,
                    session_id: (sessionStart?.id),
                };

                // Handle response
                setTimeout(async () => {
                    const id = customerId ? btoa(customerId) : btoa("753982145");

                    const res = await axiosInstance.post(`/user-promo-code/store`, payload, {
                        headers: {
                            user_token: id,
                        },
                    });
                    setModalData(res?.data?.message || "Promo Code Applied Successfully!");
                    setIsOpen(true);
                }, 3800)
            }
            navigate("/leaderboard");
           
        } catch (e) {
            console.error("Error handling spin click:", e.message);
            // window.location.reload();
        }
    };

    if (loading || roundLoading) return <CustomSpinner />;

    if (!sessionStart)
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="fs-3">Session not found !</div>
            </div>
        );

    return (
        <>
            {isLoaded ? (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="thank-you d-flex justify-content-center align-items-center flex-column">
                        <ThankyouPage text={"Thank you for submitting your answer. Get ready for the next round if you qualify!"} />
                    </div>
                </div>
            ) : (
                <div className="spin-wheel container mt-5 mb-5">
                    {/* <div className="heading mb-5 d-flex flex-column justify-content-center align-items-center w-100">
                        <h1>{questions?.length > 0 ? questions[0]?.description : "Loading..."}</h1>
                    </div> */}
                    {/* <div className="d-flex w-100 btn-leader-board mb-3">
                        <div className="ms-auto">
                            <button
                                className="rounded-3 fw-bold border-0 py-2 px-3 mt-1 bg-warning text-black"
                                onClick={handleShow}
                            >
                                See Leader Board
                            </button>
                        </div>
                    </div> */}
                    <Row className="row-spinner p-5 display-flex align-items-center">
                        <Col md={12} lg={6} className="content-column">
                            <div className="content">
                                <h1 className="mb-4">Spin The Wheel</h1>
                                <p className="mb-4">Welcome to our exclusive Spin to Win game! Simply click on the wheel to spin and stand a chance to win amazing discounts, free products, and other fantastic prizes. Each spin gives you an opportunity to score big and make your shopping experience even more rewarding.</p>
                                <h3 className="mb-2">Prizes Include:</h3>
                                <ul className="mb-5">
                                    <li>Exclusive discounts up to 50%</li>
                                    <li>Free shipping on your next order</li>
                                    <li>Complimentary products</li>
                                    <li>And many more surprises!</li>
                                </ul>
                                <Button onClick={handleSpinClick} disabled={mustSpin}>Spin Now</Button>
                            </div>
                        </Col>
                        <Col md={{ span: 12, offset: 0 }} lg={{ span: 5, offset: 1 }} className="spinner-column">
                            {spinOption?.length > 0 &&
                                <SpinWheel
                                    mustSpin={mustSpin}
                                    setMustSpin={setMustSpin}
                                    handleSpinClick={handleSpinClick}
                                    prizeNumber={prizeNumber}
                                    data={spinOption}
                                // onComplete={onSpinComplete} // Pass spin complete handler
                                />
                            }
                        </Col>
                    </Row>
                </div>
            )}
            {/* Leaderboard Modal */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2> Top {leaderBoardData?.length} {leaderBoardData?.length > 1 ? "players" : "player"} who qualified for next round </h2>
                    <p>
                        <b> Name </b>
                    </p>
                    <div className="leader-board pe-3">
                        <UserLeaderBoard />
                    </div>
                </Modal.Body>
            </Modal>
            <ThemeModal
                size={"sm"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className={"logout-modal flex-custom-space-center"}
                modalContent={<ModalData data={modalData} />}
            />
        </>
    );
};

export default Dashboard;
